import React, { useContext, useState } from "react";
import "./courseCard.css";


import ClassModal from "../Classes/ClassModal";
import { useQuery } from "@tanstack/react-query";
import { AuthContext } from "../../Context/AuthProvider";
import useAdmin from "../../Hooks/UseAdmin";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import Loading from "../../Shared/Loading";

const CoursesCard = () => {
  const { user } = useContext(AuthContext);
  const [isAdmin] = useAdmin(user?.email);
  const [newClass, setNewClass] = useState("");

  const url = `https://brooklyn-updated-server-v2.vercel.app/classes/allClasses/Ongoing`;

  const {
    data: allClasses = [],
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["Ongoing"],
    queryFn: async () => {
      const res = await fetch(url);
      const data = await res.json();
      return data;
    },
  });
  // console.log(allClasses)

  const deleteHandler = (singleClass) => {
    // console.log(setNewClass)
    const proceed = window.confirm(
      `Are you sure, you want to delete "${singleClass?.classTitle}" ?`
    );
    if (proceed) {
      fetch(
        `https://brooklyn-updated-server-v2.vercel.app/classes/${singleClass?._id}`,
        {
          method: "DELETE",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.deletedCount) {
            toast.success("class Delete SuccessFully");
            refetch();
          }
        });
    }
  };

  

  const classHandler = (newClass) => {
    setNewClass(newClass);
  };

  return (
    <div className="mb-20 ">
      <div className="md:mt-14 mt-10 text-center w-[95%] md:w-[50%] mx-auto">
        <h1 className=" playrify text-2xl md:text-4xl font-semibold dark:text-gray-100">
          Ongoing Classes
        </h1>
        <div className="flex justify-center mt-3">
          <hr className="w-14 border-t-[1px] border-[#CA4757] " />
        </div>
        <p className="Poppins mt-5 md:text-xl leading-relaxed font-medium dark:text-gray-100">
        Brooklyn Institute of Vocational Training provides ongoing courses as part of comprehensive programs. The Schedules of ongoing classes are:
        </p>
      </div>
      {isLoading && (
        <>
          <div className="my-5">
            <Loading></Loading>
          </div>
        </>
      )}



      {/* three card start  */}
      <div className=" w-[93%] md:w-[95%] lg:w-[80%]  mx-auto laptop-device">
        {allClasses?.length === 3 && (
          <>
            <div
              className="grid md:grid-cols-2 lg:grid-cols-3 card-grid gap-7 mt-10 "
              data-aos="zoom-in"
              data-aos-duration="1500"
            >
              {allClasses?.map((singleClass, index) => (
                <div
                  key={index}
                  className="custom-shadow hover-effect2 parent-class rounded-md"
                >
                  <div
                    className=" h-56 card-rounded1 rounded-md  polygon-shape"
                    style={{
                      background: `linear-gradient(217deg, rgba(0, 0, 0, 0.45) 0%, rgba(0, 0, 0, 0.6) 100%), url(${singleClass?.img})`,
                      "backgroundSize": "cover",
                    }}
                  >
                    <p className="playrify text-3xl font-semibold  text-white text-center pt-28">
                      {singleClass?.classTitle}
                    </p>
                  </div>

                  <div className="text-center mb-8">
                    <p className="Poppins font-semibold text-lg  mb-1 mt-3 dark:text-gray-100">
                     Start Date: {singleClass?.startDate}
                    </p>
                    <p className="Poppins font-semibold text-lg  mb-1 mt-1 dark:text-gray-100">
                     End Date: {singleClass?.endDate}
                    </p>

                    <ul className="mb-6  Poppins font-medium text-base lg:text-lg px-4  lg:px-0 ">
                      {singleClass?.description?.map((des,index) => (
                        <li key={index} className="leading-relaxed dark:text-gray-100">
                          {des}
                        </li>
                      ))}
                       <li className="leading-relaxed dark:text-gray-100">
                    Opening Hours: <span className="ml-1 font-medium">{singleClass?.openingHour}</span>
                  </li>
                      <li className="leading-relaxed dark:text-gray-100">
                        ${singleClass?.registration} Registration | $
                        {singleClass?.tuition} Tuition | Textbook $
                        {singleClass?.books}
                      </li>
                      <li className="leading-relaxed dark:text-gray-100">
                        Total: $
                        {parseInt(`${singleClass?.registration}`) +
                          parseInt(`${singleClass?.tuition}`) +
                          parseInt(`${singleClass?.books}`)}
                      </li>
                    </ul>

                    <div className="py-4">
                      <label
                        onClick={() => classHandler(singleClass)}
                        htmlFor="course-card"
                        className="text-center Poppins text-[#CA4757] font-medium px-5 py-3 border-[1px] border-[#CA4757] rounded-full child-class cursor-pointer"
                      >
                        Read More
                      </label>
                    </div>
                  </div>
                  {user?.email && isAdmin && (
                    <div className="flex justify-evenly my-3">
                      <Link to={`/dashboard/singleClass/${singleClass?._id}`}>
                        <button className="px-6 py-2 rounded-lg bg-[#00A6B2] btn btn-sm font-medium text-gray-100">
                          Update
                        </button>
                      </Link>

                      <button
                        onClick={() => deleteHandler(singleClass)}
                        className="px-6 py-2 btn btn-sm rounded-lg bg-primary font-medium text-gray-100"
                      >
                        Delete
                      </button>
                    </div>
                  )}
                </div>
              ))}
            </div>

            {/* three card end  */}
          </>
        )}
        {allClasses?.length === 2 && (
          <>
            <div
              className="grid md:grid-cols-2 lg:grid-cols-2  card-grid gap-7 mt-10 md:w-[65%] mx-auto medium-device"
              data-aos="zoom-in"
              data-aos-duration="1500"
            >
              {allClasses?.map((singleClass, index) => (
                <div
                  key={index}
                  className="custom-shadow hover-effect2 parent-class rounded-md"
                >
                  <div
                    className=" h-72 card-rounded1 rounded-md "
                    style={{
                      background: `linear-gradient(217deg, rgba(0, 0, 0, 0.45) 0%, rgba(0, 0, 0, 0.6) 100%), url(${singleClass?.img})`,
                      "backgroundSize": "cover",
                    }}
                  >
                    <p className="playrify text-3xl font-semibold  text-white text-center pt-28">
                      {singleClass?.classTitle}
                    </p>
                  </div>

                  <div className="text-center mb-8">
                    <p className="Poppins font-semibold text-lg  mb-2 mt-3 dark:text-gray-100">
                      {singleClass?.startDate}
                    </p>
                    <ul className="mb-6  Poppins font-medium text-base lg:text-lg px-4  lg:px-0 ">
                      {singleClass?.description.map((des, index) => (
                        <li key={index} className="leading-relaxed dark:text-gray-100">
                          {des}
                        </li>
                      ))}
                      <li className="leading-relaxed dark:text-gray-100">
                        ${singleClass?.registration} Registration | $
                        {singleClass?.tuition} Tuition | Textbook $
                        {singleClass?.books}
                      </li>
                      <li className="leading-relaxed dark:text-gray-100">
                        Total: $
                        {parseInt(`${singleClass?.registration}`) +
                          parseInt(`${singleClass?.tuition}`) +
                          parseInt(`${singleClass?.books}`)}
                      </li>
                    </ul>

                    <div className="py-4">
                      <label
                        onClick={() => classHandler(singleClass)}
                        htmlFor="course-card"
                        className="text-center Poppins text-[#CA4757] font-medium px-5 py-3 border-[1px] border-[#CA4757] rounded-full child-class cursor-pointer"
                      >
                        Read More
                      </label>
                    </div>
                  </div>
                  {user?.email && isAdmin && (
                    <div className="flex justify-evenly my-3">
                      <Link to={`/singleClass/${singleClass?._id}`}>
                        <button className="px-6 py-2 rounded-lg bg-[#00A6B2] btn btn-sm font-medium text-gray-100">
                          Update
                        </button>
                      </Link>

                      <button
                        onClick={() => deleteHandler(singleClass)}
                        className="px-6 py-2 btn btn-sm rounded-lg bg-primary font-medium text-gray-100"
                      >
                        Delete
                      </button>
                    </div>
                  )}
                </div>
              ))}
            </div>

            {/* three card end  */}
          </>
        )}
      </div>

      <ClassModal newClass={newClass}></ClassModal>
    </div>
  );
};

export default CoursesCard;
