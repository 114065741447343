import React from 'react';
import { BsFillTelephoneFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import welcomePic from '../../../Assets/ceo-welcome1.jpg';

const Welcome = () => {
    return (
        <div>
           <div className='w-[95%] md:w-[95%] lg:w-[80%] welcome-message mx-auto bg-[#e8f3f8] rounded-2xl  mt-16 shadow-md dark:bg-slate-700'>

            <div className='grid grid-cols-4 md:grid-cols-5 gap-7 p-6 md:p-12 items-center'>

                <div className='md:col-span-3 col-span-4'>
                    <h1 className=' text-lg lg:text-3xl leading-loose font-bold text-[#A13744] playrify dark:text-gray-100'>
                    Welcome Message from our Directors, <br /> Navin and Arvin Shivpal
                    </h1>
                    <p className=' mt-3 lg:mt-5 lg:text-lg  text-justify roboto tracking-tight dark:text-gray-100'>
                    We created this institution in order to not only give back but give power to our community. That is why we prefer to stay connected with the community, so please call us if you have any questions or concerns. As we like to believe, "Changing Lives, One Student at a Time".
                    </p>
                    <div className='lg:flex gap-5 items-center'>
                    <p className="md:mt-4 mt-2  font-medium flex gap-2 items-center  md:text-xl source-sans dark:text-gray-100">
                    Navin -{" "}
                    <a
                      href="tel:+1347-975-2525"
                      className="hover:text-[#CA4757] flex items-center gap-2"
                    >
                      <BsFillTelephoneFill  className='text-[#CA4757]' /> 347-975-2525 ,{" "}
                    </a>
                  </p> 
                  <p className=" md:mt-4 mt-2  font-medium  flex gap-2 items-center  md:text-xl source-sans dark:text-gray-100">
                    Arvin -{" "}
                    <a
                      href="tel:+1347-969-1626"
                      className="hover:text-[#CA4757] flex  gap-2"
                    >
                      <BsFillTelephoneFill  className='text-[#CA4757]' /> 347-969-1626{" "}
                    </a>
                  </p>
                    </div>

                  <Link to='/classes'>
                    <button className='bg-[#CA4757] px-6 py-3 rounded-full text-white font-semibold mt-6 shadow-md hover-effect dark:bg-slate-200 dark:text-gray-700'>
                        Find Out More

                    </button>
                  </Link>

                </div>
                <div className='order-first sm:order-none md:col-span-2 col-span-4'>
                  <img src={welcomePic} className=' h-94 lg:w-[350px] 
                    ceo-picture mx-auto  rounded-lg' alt="" />  
                </div>

            </div>




            </div> 
        </div>
    );
};

export default Welcome;