import React from "react";
import AboutUs from "./AboutUs";
import Banner from "./Banner";
import ContactCard from "./ContactCard";
import CountDown from "./CountDown";
import JoinMail from "./JoinMail";
import Licence from "./Licence";
import Mission from "./Mission/Mission";
import Welcome from "./Welcome/Welcome";
import Chat from "../../Shared/Chat/Chat";
import { useQuery } from "@tanstack/react-query";
import OdometerComponent from "../Odometer/OdometerComponent";
import Testimonial from "./Testimonial/Testimonial";
import PageComponent from "../../PageComponent/PageComponent";



const Home = () => {
 

  const url = "https://brooklyn-updated-server-v2.vercel.app/classes/countDown";
  const {
    data: countDown = [],
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["countDown"],
    queryFn: async () => {
      const res = await fetch(url);
      const data = await res.json();
      return data;
    },
  });

  return (
    <div className="dark:bg-slate-600">
    <PageComponent 
    title="Home - Brooklyn Institute Of Vocational Training" 
    description="Brooklyn Institute of Vocational Training is a premier educational institution in New York City specializing in comprehensive Home Health Aide (HHA) training. Our programs are designed to equip students with the essential skills and knowledge required to excel in the growing field of home healthcare. Enroll today to start your career as a certified HHA."
    keywords="Brooklyn Institute of Vocational Training, Brooklyn Institute, Brooklyn Vocational Training, HHA Training NYC, Home Health Aide Certification, Home Care Training New York City, Become an HHA in NYC, Healthcare Vocational Training, HHA Classes NYC, Certified Home Health Aide Training, Healthcare Careers NYC, HHA School Brooklyn"
/>
      <Banner></Banner>
      <div className=" dark:bg-slate-600">
        <ContactCard></ContactCard>
        <Welcome></Welcome>     
        <Testimonial/>
        <Mission></Mission>
        <Licence></Licence>
      </div>

      {countDown?.length > 0 &&
        <CountDown countDown={countDown}>
        </CountDown>}
        
      {/* <NewCountDown></NewCountDown> */}
      <OdometerComponent></OdometerComponent>
      <AboutUs></AboutUs>
      <JoinMail></JoinMail>

      <Chat></Chat>
    </div>
  );
};

export default Home;
