import React, { useEffect, useState } from 'react'
import VideoList from './VideoList';
import VideoPlayer from './VideoPlayer';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';

// import video1 from '../../../src/Assets/video-gallery/video-1.mp4'
// import video2 from '../../../src/Assets/video-gallery/Justin Bellas.mp4'
// import video3 from '../../../src/Assets/video-gallery/poonam singh.mp4'
// import video4 from '../../../src/Assets/video-gallery/video-4.mp4'
// import video5 from '../../../src/Assets/video-gallery/ancal-manager.mp4'
// import video6 from '../../../src/Assets/video-gallery/teacher.mp4'
// import video7 from '../../../src/Assets/video-gallery/video-7.mp4'
// import video8 from '../../../src/Assets/video-gallery/video-8.mp4'
// import video9 from '../../../src/Assets/video-gallery/video-9.mp4'
// import video10 from '../../../src/Assets/video-gallery/video-10.mp4'
// import video11 from '../../../src/Assets/video-gallery/video-11.mp4'
// import video12 from '../../../src/Assets/video-gallery/video-12.mp4'
// import video13 from '../../../src/Assets/video-gallery/video-16.mp4'
// import video14 from '../../../src/Assets/video-gallery/video-14.mp4'
// import video15 from '../../../src/Assets/video-gallery/video-15.mp4'
// import video16 from '../../../src/Assets/video-gallery/video-17.mp4'
// import video17 from '../../../src/Assets/video-gallery/video-18.mp4'
// import video18 from '../../../src/Assets/video-gallery/video-19.mp4'
import gradma from '../../../src/Assets/video-gallery/grandma.JPG'
import img1 from '../../../src/Assets/video-gallery/img-1.JPG'
import img2 from '../../../src/Assets/video-gallery/img-3.jpg'
import img3 from '../../../src/Assets/video-gallery/ancal.jpg'
import img4 from '../../../src/Assets/video-gallery/img-4.JPG'
import img5 from '../../../src/Assets/video-gallery/img-5.JPG'
import img6 from '../../../src/Assets/video-gallery/img-6.JPG'
import img7 from '../../../src/Assets/video-gallery/img-7.JPG'
import img8 from '../../../src/Assets/video-gallery/img-8.JPG'
import img9 from '../../../src/Assets/video-gallery/img-9.JPG'
import img10 from '../../../src/Assets/video-gallery/img-10.JPG'
import img11 from '../../../src/Assets/video-gallery/img-11.JPG'
import img12 from '../../../src/Assets/video-gallery/img-12.JPG'
import img13 from '../../../src/Assets/video-gallery/img-13.JPG'
import img14 from '../../../src/Assets/video-gallery/img-14.JPG'
import img15 from '../../../src/Assets/video-gallery/img-15.JPG'
import img16 from '../../../src/Assets/video-gallery/img-16.JPG'
import img17 from '../../../src/Assets/video-gallery/img-17.JPG'

const VideoGallery = () => {

  const [selectedVideo, setSelectedVideo] = useState(null);

  const videos = [
    {
      title: 'Trinidadian Chutney star Savita Singh visits BIVT',
      url: 'https://res.cloudinary.com/dlw7u1u5p/video/upload/v1728487950/upv2gpdtxjcjdug67vxr.mp4',
      width: "savita",
      img: img1
    },
    { title: 'Jamaican reggae star Christopher Martin surprises students at BIVT ', url: 'https://res.cloudinary.com/dlw7u1u5p/video/upload/v1728488006/x8ho7fteusvhsxfb1p9r.mp4', width: true, img: img2, crisToper: true },
    { title: '62-year-old grandmother graduates with HHA certificate', url: 'https://res.cloudinary.com/dlw7u1u5p/video/upload/v1728488040/wvu4tjmhwncgrtyj0kvm.mp4', width: "grandma", img: gradma },
    
    { title: 'Guyanese singer Poonam Singh is inspired BIVT empowerment initiatives for students', url: 'https://res.cloudinary.com/dlw7u1u5p/video/upload/v1728488081/qhl7bcjrleknwhc3vmfe.mp4', img: img6 },
    { title: 'Graduate overwhelmed with excitement after graduating from BIVT ', url: 'https://res.cloudinary.com/dlw7u1u5p/video/upload/v1728488117/gsfebted9vjqt1ghiei0.mp4', img: img7 },
    { title: 'HHA graduates honor BIVT teacher', url: 'https://res.cloudinary.com/dlw7u1u5p/video/upload/v1728488169/gntmrsnd0sa2yowmhppg.mp4', img: img8 },
    { title: 'Teaching is more than imparting knowledge; it is inspiring change.', url: 'https://res.cloudinary.com/dlw7u1u5p/video/upload/v1728488199/zjc7xpvwsocefy7tpmhr.mp4', img: img9 },
    { title: 'A former teacher from Guyana, graduates with his HHA certificate despite numerous obstacles', url: 'https://res.cloudinary.com/dlw7u1u5p/video/upload/v1728488236/bxv0arqqf6hwfjfbzihk.mp4', img: img10 },
    { title: 'Which of our graduates has the best dance move ', url: 'https://res.cloudinary.com/dlw7u1u5p/video/upload/v1728488268/ycooozxsx2zgdavuaf94.mp4', width: true, img: img4 },
    { title: 'Guyanese Chutney singer Fiona Singh completes her HHA training.', url: 'https://res.cloudinary.com/dlw7u1u5p/video/upload/v1728488309/ilcnuosuphwplzmpozra.mp4', img: img11 },
    { title: 'A career as a home health aide can be challenging yet highly rewarding.', url: 'https://res.cloudinary.com/dlw7u1u5p/video/upload/v1728488342/aaswqmt7xffcn4pngd8n.mp4', img: img12 },
    { title: 'Her sister’s keeper” Zahida and Sharida encourages women to invest in themselves ', url: 'https://res.cloudinary.com/dlw7u1u5p/video/upload/v1728488382/b8fht9rimpaxww14vedz.mp4', img: img13 },
    { title: "Congratulations: It's a celebration at BIVT  for our newly certified HHAs", url: 'https://res.cloudinary.com/dlw7u1u5p/video/upload/v1728488415/gnkiw2esvibetqopatzl.mp4', img: img14 },
    { title: 'Kamlawattie is overwhelmed with emotions as she experiences her first graduation at age 37', url: 'https://res.cloudinary.com/dlw7u1u5p/video/upload/v1728488463/ossciqilhu9eltcxfrfu.mp4', width: true, img: img5 },
    { title: 'Student shares her experience studying at BIVT', url: 'https://res.cloudinary.com/dlw7u1u5p/video/upload/v1728488493/qqx8ex4k7r8hscxwepm7.mp4', img: img15 },
    { title: "Graduation Day: After handwork it's a celebration", url: 'https://res.cloudinary.com/dlw7u1u5p/video/upload/v1728488520/hxhipzjxe8ggwko8sf0l.mp4', img: img16 },
    { title: 'Student travels from the Bronx to Queens to attend BIVT ', url: 'https://res.cloudinary.com/dlw7u1u5p/video/upload/v1728488549/xa356cuy3az4v4tobmer.mp4', img: img17 },

  ];

  useEffect(() => {
    // Set the selected video to the first video in the playlist when the component mounts
    setSelectedVideo(videos[0]);
  }, []);

  const onVideoSelect = (video) => {
    setSelectedVideo(video);
  };

  return (
    <div className='min-h-screen py-5 w-[95%] lg:w-[80%] mx-auto'>
      <div className=" md:mb-10 md:mt-10 my-5">
        <h1 className="text-gray-700 text-center  text-xl  advertise md:text-4xl font-bold dark:text-gray-100">
          {" "}
          Video Gallery
        </h1>
        <div className="flex justify-center ">
          <hr className="bg-primary px-5 md:mt-2 mt-2 py-[1px] w-[100px]" />
        </div>
      </div>
      <div className='md:grid grid-cols-6 gap-5'>
        <div className='md:col-span-4'>
          <VideoPlayer selectedVideo={selectedVideo} />
        </div>
        <div className='md:col-span-2'>
          <VideoList videos={videos} onVideoSelect={onVideoSelect} />
        </div>


      </div>
    </div>
  )
}

export default VideoGallery
