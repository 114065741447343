import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Footer from '../Pages/Footer/Footer';
import whatsAppIcon from '../../src/Assets/Icon/whatsapp.png'

import TopBar from '../Pages/TopBar';
import { NavBar } from '../Pages/NavBar/NavBar';

const Main = () => {

    const [property, setShowProperty] = useState('')
    const location= useLocation()
    const pathname = location.pathname;

    useEffect(() => {
  
        if (pathname === '/conversation'  ) {
          setShowProperty('hidden');
        } else {
          setShowProperty(''); 
        }
      }, [pathname]);

    return (
        <div>
            <TopBar></TopBar>
            <NavBar></NavBar>
            <div className='overflow-hidden'>
                <Outlet></Outlet>
            </div>

            <div
          className={`${property} md:w-[60px] md:h-[60px] w-11 h-11 z-40   big-display  cursor-pointer fixed bottom-[78px] md:bottom-[112px] right-2.5 md:right-6`}>
          <a
            href="https://wa.me/13479752525?text=Hello%20there!" target="_blank"
            className="  flex justify-center text-center  md:text-7xl  text-primary ">
                <img src={whatsAppIcon} className="w-full h-full " alt="" />
          </a>
        </div>

            <Footer></Footer>

        </div>
    );
};

export default Main;