import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import "./ClassForm.css";
import Loading from "../../../Shared/Loading";

const ClassForm = () => {
  const [loading, setLoading] = useState(null);
  const date = Date.now();
  const imageHostKey = process.env.REACT_APP_imgbb_key;
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const submitHandler = (data) => {
    setLoading(true);
    // console.log(data);

    const image = data.url[0];
    const formData = new FormData();
    formData.append("image", image);
    const url = `https://api.imgbb.com/1/upload?key=${imageHostKey}`;

    fetch(url, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((imgData) => {
        console.log(imgData.data.url);
        if (imgData.success) {
          const singleClass = {
            classTitle: data?.classTitle,
            classType: data?.classType,
            startDate2: data?.startDate2,
            startDate3: data?.startDate3,
            endDate: data?.endDate,
            newDate: date,
            img: imgData.data.url,
            description: [
              data?.duration,
              data?.weekdays
            ],
            registration: data?.registration,
            tuition: data?.tuition,
            books: data?.books,
            openingHour:data?.openingHour,
          };

          //   console.log(singleClass)

          fetch("https://brooklyn-updated-server-v2.vercel.app/classes/addClass", {
            method: "POST",
            headers: {
              "content-type": "application/json",
            },
            body: JSON.stringify(singleClass),
          })
            .then((res) => res.json())
            .then((data) => {
              console.log(data);
              setLoading(false);
              if (data.acknowledged) {
                toast.success("class Added SuccessFully");
                reset();
                navigate("/classes");
              }
            });
        }
      });
  };
  return (
    <div className="pt-5 classroom-bg" id="contact-form">
      {loading && (
        <div className="my-5">
          <Loading></Loading>
        </div>
      )}
      <section className="flex justify-center items-center pb-8 mt-3 mb-12 rounded w-11/12  md:w-[80%] mx-auto big-screen">
        <div className="w-full  " data-aos="fade-up" data-aos-duration="2000">
          <form
            onSubmit={handleSubmit(submitHandler)}
            className="max-w-xl md:max-w-3xl   ml-auto bg-gray-50 p-8 rounded-md shadow-xl dark:bg-slate-600 "
          >
            <h1 className="text-2xl text-gray-600 text-center Poppins font-semibold">
              {" "}
              Add A Class
            </h1>

            <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
              <div className="space-y-0.5   text-sm">
                <label
                  htmlFor="firstName"
                  className="block  dark:text-gray-200"
                >
                  Class Schedule
                </label>
                <input
                  type="text"
                  {...register("classTitle", {
                    required: "Class Name is required",
                  })}
                  id="class-Name"
                  placeholder="Class Schedule"
                  className="w-full px-4 py-3 rounded-md border text-gray-700 focus:outline-[#00a6b265] bg-[#EBF8F9]
                                          focus:bg-white shadow-md dark:bg-gray-400 dark:text-gray-200 dark:placeholder:text-gray-100"
                />

                {errors.classTitle && (
                  <p className="text-red-600">{errors.classTitle.message}</p>
                )}
              </div>

              <div className="space-y-0.5 text-sm">
                <div className="flex items-center gap-4">
                  <label htmlFor="file" className="block  dark:text-gray-200">
                    Image
                  </label>

                  <small className="text-red-500">
                    [ Image should not be large then 200 KB ]{" "}
                  </small>
                </div>

                <input
                  type="file"
                  {...register("url", {
                    required: "Image is required",
                  })}
                  className="file-input file-input-secondary border-0  w-full  text-gray-700 focus:outline-[#00a6b265] bg-[#EBF8F9] focus:bg-white shadow-md dark:bg-gray-400 dark:text-gray-200 dark:placeholder:text-gray-100"
                />

                {errors.url && (
                  <p className="text-red-600">{errors.url.message}</p>
                )}
              </div>
            </div>

            <hr className="my-3" />

            <div className="space-y-1 text-sm mt-3">
              <label htmlFor="option" className="block dark:text-gray-200">
                Class Type
              </label>
              <select
                {...register("classType", {
                  required: "Please Select One",
                })}
                id="classType"
                className="w-full px-4 py-3 rounded-md border text-gray-700 focus:outline-[#00a6b265] bg-[#EBF8F9]
                                     shadow-md dark:bg-gray-400 dark:text-gray-200 dark:placeholder:text-gray-100"
              >
                <option value={""} disabled hidden selected required>
                  Select One
                </option>
                <option value="Upcoming">Upcoming</option>
                <option value="Ongoing">Ongoing</option>
              </select>
              {errors.classType && (
                <p className="text-red-600" role="alert">
                  {errors.classType.message}
                </p>
              )}
            </div>
         

            <hr className="my-3" />

            <div className="space-y-0.5   text-sm">
              <input
                type="text"
                {...register("startDate", {
                  required: "Class Start Date is required",
                })}
                id="startDate"
                placeholder="Class Start Date"
                className="w-full px-4 py-3 rounded-md border text-gray-700 focus:outline-[#00a6b265] bg-[#EBF8F9]
                    focus:bg-white shadow-md dark:bg-gray-400 dark:text-gray-200 dark:placeholder:text-gray-100"
              />

              {errors.startDate && (
                <p className="text-red-600">{errors.startDate.message}</p>
              )}
            </div>
            


            <div className="space-y-0.5 mt-2   text-sm">
              <input
                type="text"
                {...register("startDate2")}
                id="startDate-2"
                placeholder="Class Start Date-2"
                className="w-full px-4 py-3 rounded-md border text-gray-700 focus:outline-[#00a6b265] bg-[#EBF8F9]
                    focus:bg-white shadow-md dark:bg-gray-400 dark:text-gray-200 dark:placeholder:text-gray-100"
              />
            </div>
            <div className="space-y-0.5 mt-2   text-sm">
              <input
                type="text"
                {...register("startDate3")}
                id="startDate-3"
                placeholder="Class Start Date-3"
                className="w-full px-4 py-3 rounded-md border text-gray-700 focus:outline-[#00a6b265] bg-[#EBF8F9]
                    focus:bg-white shadow-md dark:bg-gray-400 dark:text-gray-200 dark:placeholder:text-gray-100"
              />

            </div>

            <div className="space-y-0.5   text-sm my-1">
              <input
                type="text"
                {...register("endDate", {
                  required: "Class Start Date is required",
                })}
                id="endDate"
                placeholder="Class End Date"
                className="w-full px-4 py-3 rounded-md border text-gray-700 focus:outline-[#00a6b265] bg-[#EBF8F9]
                    focus:bg-white shadow-md dark:bg-gray-400 dark:text-gray-200 dark:placeholder:text-gray-100"
              />

              {errors.endDate && (
                <p className="text-red-600">{errors.endDate.message}</p>
              )}
            </div>
            <div className="space-y-0.5   text-sm my-1">
              <input
                type="text"
                {...register("nextUpcoming")}
                id="nextUpcoming"
                placeholder="Next Upcoming Class"
                className="w-full px-4 py-3 rounded-md border text-gray-700 focus:outline-[#00a6b265] bg-[#EBF8F9]
                    focus:bg-white shadow-md dark:bg-gray-400 dark:text-gray-200 dark:placeholder:text-gray-100"
              />

              {errors.nextUpcoming && (
                <p className="text-red-600">{errors.nextUpcoming.message}</p>
              )}
            </div>
            <div className="space-y-0.5   text-sm my-1">
              <input
                type="text"
                {...register("nextEnd")}
                id="nextEnd"
                placeholder="Next Upcoming Class"
                className="w-full px-4 py-3 rounded-md border text-gray-700 focus:outline-[#00a6b265] bg-[#EBF8F9]
                    focus:bg-white shadow-md dark:bg-gray-400 dark:text-gray-200 dark:placeholder:text-gray-100"
              />

              {errors.nextEnd && (
                <p className="text-red-600">{errors.nextEnd.message}</p>
              )}
            </div>

            <hr className="my-3" />

            <div className="space-y-0.5   text-sm">
              <input
                type="text"
                {...register("duration", {
                  required: "Class Duration is required",
                })}
                id="duration"
                placeholder="Class Duration"
                className="w-full px-4 py-3 rounded-md border text-gray-700 focus:outline-[#00a6b265] bg-[#EBF8F9]
                    focus:bg-white shadow-md dark:bg-gray-400 dark:text-gray-200 dark:placeholder:text-gray-100"
              />

              {errors.duration && (
                <p className="text-red-600">{errors.duration.message}</p>
              )}
            </div>
            <hr className="my-3" />

            <div className="space-y-0.5   text-sm">
              <input
                type="text"
                {...register("openingHour", {
                  required: "Opening Hour is required",
                })}
                id="openingHou"
                placeholder="Opening Hours (start) - opening Hours (End) "
                className="w-full px-4 py-3 rounded-md border text-gray-700 focus:outline-[#00a6b265] bg-[#EBF8F9]
                    focus:bg-white shadow-md dark:bg-gray-400 dark:text-gray-200 dark:placeholder:text-gray-100"
              />

              {errors.openingHour && (
                <p className="text-red-600">{errors.openingHour.message}</p>
              )}
            </div>
            <hr className="my-3" />

            <div className="space-y-0.5   text-sm">
              <input
                type="text"
                {...register("weekdays", {
                  required: "Weekdays is required",
                })}
                id="weekdays"
                placeholder="Start Weekday - End Weekday"
                className="w-full px-4 py-3 rounded-md border text-gray-700 focus:outline-[#00a6b265] bg-[#EBF8F9]
                    focus:bg-white shadow-md dark:bg-gray-400 dark:text-gray-200 dark:placeholder:text-gray-100"
              />

              {errors.weekdays && (
                <p className="text-red-600">{errors.weekdays.message}</p>
              )}
            </div>

            <hr className="my-3" />

            <div className="space-y-0.5   text-sm flex items-center justify-evenly">
              <div>
                <input
                  type="number"
                  {...register("registration", {
                    required: "Required",
                  })}
                  id="registration"
                  placeholder="Registration Fee: $"
                  className="w-full px-4 py-3 rounded-md border text-gray-700 focus:outline-[#00a6b265] bg-[#EBF8F9]
                    focus:bg-white shadow-md dark:bg-gray-400 dark:text-gray-200 dark:placeholder:text-gray-100"
                />

                {errors.registration && (
                  <p className="text-red-600 mt-1">
                    {errors.registration.message}
                  </p>
                )}
              </div>
              <div>
                <input
                  type="number"
                  {...register("tuition", {
                    required: "Required",
                  })}
                  id="tuition"
                  placeholder="Tuition Fee: $"
                  className="w-full px-4 py-3 rounded-md border text-gray-700 focus:outline-[#00a6b265] bg-[#EBF8F9]
                    focus:bg-white shadow-md dark:bg-gray-400 dark:text-gray-200 dark:placeholder:text-gray-100"
                />

                {errors.tuition && (
                  <p className="text-red-600 mt-1">{errors.tuition.message}</p>
                )}
              </div>
              <div>
                <input
                  type="number"
                  {...register("books", {
                    required: "Required",
                  })}
                  id="books"
                  placeholder="Textbook Fee: $"
                  className="w-full px-4 py-3 rounded-md border text-gray-700 focus:outline-[#00a6b265] bg-[#EBF8F9]
                    focus:bg-white shadow-md dark:bg-gray-400 dark:text-gray-200 dark:placeholder:text-gray-100"
                />

                {errors.books && (
                  <p className="text-red-600">{errors.books.message}</p>
                )}
              </div>
            </div>

            <button className="block w-full p-3 text-center rounded-sm  bg-primary hover:bg-secondary text-white mt-6">
              Upload
            </button>
          </form>
        </div>
      </section>
    </div>
  );
};

export default ClassForm;
