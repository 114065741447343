import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-hot-toast";
import Loading from "../../../Shared/Loading";
import ViewPdf from "./ViewPdf";
import { useNavigate } from "react-router-dom";

function PdfUploader() {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [filename, setFilename] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleFileChange = (event) => {
    setSelectedFiles(event.target.files);
  };

  const handleFilenameChange = (event) => {
    setFilename(event.target.value);
  };

  const handleUpload = async () => {
    setLoading(true);
    if (!selectedFiles.length || !filename) {
      setLoading(false);
      toast.error("Please select PDF files and provide a filename.");
      return;
    }

    const formData = new FormData();
    formData.append("filename", filename);

    for (const file of selectedFiles) {
      formData.append("pdfs", file);
    }

    try {
      await axios.post(
        "https://brooklyn-updated-server-v2.vercel.app/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setLoading(false);
      toast.success("PDFs uploaded successfully");
      navigate("/classes");
    } catch (error) {
      setLoading(false);
      toast.error("Error uploading PDFs", error);
    }
  };

  if (loading) {
    return <Loading></Loading>;
  }

  return (
    <div className="border-2 min-h-screen">
      <div className="flex justify-center mt-[10vh]">
        <div className=" w-[95%] md:w-[50%]   bg-gray-50 p-8 rounded-md shadow-xl dark:bg-slate-600 ">
          <p className="text-xl text-center Poppins font-semibold my-3 dark:text-gray-200 text-primary">
            Upload The Notice With PDF File
          </p>

          <div className=" space-y-2">
            <div className="space-y-0.5   text-sm">
              <label htmlFor="firstName" className="block  dark:text-gray-200">
                Select the PDF File
              </label>
              <input
                type="file"
                accept=".pdf"
                onChange={handleFileChange}
                className="w-full px-4 py-3 rounded-md border text-gray-700 focus:outline-[#00a6b265] bg-[#EBF8F9]
              focus:bg-white shadow-md dark:bg-gray-400 dark:text-gray-200 dark:placeholder:text-gray-100"
              />
            </div>
            <div className="space-y-0.5   text-sm">
              <label htmlFor="firstName" className="block  dark:text-gray-200">
                Notice Name
              </label>
              <input
                value={filename}
                onChange={handleFilenameChange}
                placeholder="Notice Name"
                className="w-full px-4 py-3 rounded-md border text-gray-700 focus:outline-[#00a6b265] bg-[#EBF8F9]
                                            focus:bg-white shadow-md dark:bg-gray-400 dark:text-gray-200 dark:placeholder:text-gray-100"
              />
            </div>
          </div>

          <hr className="my-3" />

          <button
            onClick={handleUpload}
            className="block w-full p-3 text-center rounded-sm  bg-primary hover:bg-secondary text-white mt-6"
          >
            Upload
          </button>
        </div>
      </div>
    </div>
  );
}

export default PdfUploader;
