import React, { useState } from 'react';
import Loading from '../../../Shared/Loading';
import { useForm } from 'react-hook-form';
import { useLoaderData, useNavigate } from 'react-router-dom';
import Login from '../../Login/Login';
import { toast } from 'react-hot-toast';

const ClassEditForm = () => {

    const navigate = useNavigate()
    const singleClass = useLoaderData()
    const imageHostKey = process.env.REACT_APP_imgbb_key;
    console.log(singleClass)
    const [loading, setLoading] = useState(null);


    // console.log(id)

    
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
      } = useForm();


      const updateHandler = (data)=>{
        setLoading(true)
        // console.log(data)
        
        if(data?.url?.length > 0){
            const image = data.url[0]
            const formData = new FormData();
            formData.append('image', image);
            const url = `https://api.imgbb.com/1/upload?key=${imageHostKey}`
            fetch(url, {
                method: 'POST',
                body: formData
            })
                .then(res => res.json())
                .then(imgData => {
                    if (imgData.success) {
                        const newClass = {
                            _id: singleClass?._id, 
                            classTitle :data?.classTitle,
                            classType:data?.classType,
                            img:imgData.data.url,
                            startDate:data.startDate,
                            startDate2:data.startDate2,
                            startDate3:data.startDate3,
                            endDate:data?.endDate,
                            nextUpcoming:data?.nextUpcoming,
                            nextEnd:data?.nextEnd,
                            description:[
                                data?.duration,
                                data?.weekdays
                            ],
                            registration:data?.registration,
                            tuition:data?.tuition,
                            books:data?.books,
                            openingHour:data?.openingHour
                
                        }
                        fetch(`https://brooklyn-updated-server-v2.vercel.app/classes/updateClass`, {
                            method: "PUT",
                            headers: {
                                "content-type": "application/json",
                            },
                            body: JSON.stringify(newClass),
                        })
                            .then((res) => res.json())
                            .then((data) => {
                                console.log(data);
                                setLoading(false)
                                if (data.acknowledged) {
                                    toast.success("Class Added SuccessFully")
                                    navigate('/classes')
                                }
                            });


                    }




                })

        }

        else{

            const newClass = {
                _id: singleClass?._id, 
                classTitle: data?.classTitle,
                classType:data?.classType,
                img:singleClass?.img,
                startDate:data.startDate,
                startDate2:data.startDate2,
                startDate3:data.startDate3,
                endDate:data?.endDate,
                description:[
                    data?.duration,
                    data?.weekdays
                ],
                registration:data?.registration,
                tuition:data?.tuition,
                books:data?.books,
                openingHour:data?.openingHour
    
            }
            fetch(`https://brooklyn-updated-server-v2.vercel.app/classes/updateClass`, {
                method: "PUT",
                headers: {
                    "content-type": "application/json",
                },
                body: JSON.stringify(newClass),
            })
                .then((res) => res.json())
                .then((data) => {
                    console.log(data);
                    setLoading(false)
                    if (data.acknowledged) {
                        toast.success("Class Added SuccessFully")
                        navigate('/classes')
                    }
                });

        }


      }

    return (
        <div className="pt-5 classroom-bg" id="contact-form">

            {
                loading && 
                <div className='my-5'>

                    <Loading></Loading>

                </div>
            }
       
        <section className="flex justify-center items-center pb-8 mt-3 mb-12 rounded w-11/12  md:w-[80%] mx-auto big-screen">
          <div className="w-full  " data-aos="fade-up" data-aos-duration="2000">
            <form
              onSubmit={handleSubmit(updateHandler)}
              className="max-w-xl md:max-w-3xl   ml-auto bg-gray-50 p-8 rounded-md shadow-xl dark:bg-slate-600 "
            >
              <h1 className="text-2xl text-gray-600 text-center Poppins font-semibold">
                {" "}
                Add A Class
              </h1>
  
              <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
                <div className="space-y-0.5   text-sm">
                  <label
                    htmlFor="firstName"
                    className="block  dark:text-gray-200"
                  >
                    Class Name
                  </label>
                  <input
                  defaultValue={singleClass?.classTitle}
                    type="text"
                    {...register("classTitle", {
                      required: "Class Name is required",
                    })}
                    id="class-Name"
                    placeholder="Class Name"
                    className="w-full px-4 py-3 rounded-md border text-gray-700 focus:outline-[#00a6b265] bg-[#EBF8F9]
                                            focus:bg-white shadow-md dark:bg-gray-400 dark:text-gray-200 dark:placeholder:text-gray-100"
                  />
  
                  {errors.classTitle && (
                    <p className="text-red-600">{errors.classTitle.message}</p>
                  )}
                </div>
  
                <div className="space-y-0.5 text-sm">
                  <div className="flex items-center gap-4">
                    <label htmlFor="file" className="block  dark:text-gray-200">
                      Image
                    </label>
  
                    <small className="text-red-500">
                      [ Image should not be large then 200 KB ]{" "}
                    </small>
                  </div>
  
                  <input
                //   defaultValue={singleClass?.img}
                    type="file"
                    {...register("url")}
                    className="file-input file-input-secondary border-0  w-full  text-gray-700 focus:outline-[#00a6b265] bg-[#EBF8F9] focus:bg-white shadow-md dark:bg-gray-400 dark:text-gray-200 dark:placeholder:text-gray-100"
                  />
  
                  {errors.url && (
                    <p className="text-red-600">{errors.url.message}</p>
                  )}
                </div>
              </div>
  
              <hr className="my-3" />
  
              <div className="space-y-1 text-sm mt-3">
                <label htmlFor="option" className="block dark:text-gray-200">
                  Class Type
                </label>
                <select
              
                  {...register("classType", {
                    required: "Please Select One",
                  })}
                  id="classType"
                  className="w-full px-4 py-3 rounded-md border text-gray-700 focus:outline-[#00a6b265] bg-[#EBF8F9]
                                       shadow-md dark:bg-gray-400 dark:text-gray-200 dark:placeholder:text-gray-100"
                >
                  <option 
                  
                    
                    >
                    {singleClass?.classType}
                  </option>
                  <option value="Upcoming">Upcoming</option>
                  <option value="Ongoing">Ongoing</option>
                </select>
                {errors.classType && (
                  <p className="text-red-600" role="alert">
                    {errors.classType.message}
                  </p>
                )}
              </div>
  
              <hr className="my-3" />
  
              <div className="space-y-0.5   text-sm">
                <input
                defaultValue={singleClass?.startDate}
                  type="text"
                  {...register("startDate", {
                    required: "Class Start Date is required",
                  })}
                  id="startDate"
                  placeholder="Class Start Date"
                  className="w-full px-4 py-3 rounded-md border text-gray-700 focus:outline-[#00a6b265] bg-[#EBF8F9]
                      focus:bg-white shadow-md dark:bg-gray-400 dark:text-gray-200 dark:placeholder:text-gray-100"
                />
  
                {errors.startDate && (
                  <p className="text-red-600">{errors.startDate.message}</p>
                )}
              </div>

              <div className="space-y-0.5 my-1  text-sm">
                <input
                defaultValue={singleClass?.startDate2}
                  type="text"
                  {...register("startDate2")}
                  id="startDate"
                  placeholder="Class Start Date-2"
                  className="w-full px-4 py-3 rounded-md border text-gray-700 focus:outline-[#00a6b265] bg-[#EBF8F9]
                      focus:bg-white shadow-md dark:bg-gray-400 dark:text-gray-200 dark:placeholder:text-gray-100"
                />
              </div>

              <div className="space-y-0.5 my-1  text-sm">
                <input
                defaultValue={singleClass?.startDate3}
                  type="text"
                  {...register("startDate3")}
                  id="startDate"
                  placeholder="Class Start Date-3"
                  className="w-full px-4 py-3 rounded-md border text-gray-700 focus:outline-[#00a6b265] bg-[#EBF8F9]
                      focus:bg-white shadow-md dark:bg-gray-400 dark:text-gray-200 dark:placeholder:text-gray-100"
                />
              </div>

              <div className="space-y-0.5   text-sm my-1.5">
              <input
              defaultValue={singleClass?.endDate}
                type="text"
                {...register("endDate")}
                id="endDate"
                placeholder="Class End Date"
                className="w-full px-4 py-3 rounded-md border text-gray-700 focus:outline-[#00a6b265] bg-[#EBF8F9]
                    focus:bg-white shadow-md dark:bg-gray-400 dark:text-gray-200 dark:placeholder:text-gray-100"
              />

              
            </div>
              <div className="space-y-0.5   text-sm my-1.5">
              <input
              defaultValue={singleClass?.nextUpcoming}
                type="text"
                {...register("nextUpcoming")}
                id="nextUpcoming"
                placeholder="Next Upcoming Start Date"
                className="w-full px-4 py-3 rounded-md border text-gray-700 focus:outline-[#00a6b265] bg-[#EBF8F9]
                    focus:bg-white shadow-md dark:bg-gray-400 dark:text-gray-200 dark:placeholder:text-gray-100"
              />
              
            </div>
              <div className="space-y-0.5   text-sm my-1.5">
              <input
              defaultValue={singleClass?.nextEnd}
                type="text"
                {...register("nextEnd")}
                id="nextUpcoming"
                placeholder="Next Upcoming End Date"
                className="w-full px-4 py-3 rounded-md border text-gray-700 focus:outline-[#00a6b265] bg-[#EBF8F9]
                    focus:bg-white shadow-md dark:bg-gray-400 dark:text-gray-200 dark:placeholder:text-gray-100"
              />
              
            </div>
  
              <hr className="my-3" />
  
              <div className="space-y-0.5   text-sm">
                <input

                defaultValue={singleClass?.description?.[0]}
                  type="text"
                  {...register("duration", {
                    required: "Class Duration is required",
                  })}
                  id="duration"
                  placeholder="Class Duration"
                  className="w-full px-4 py-3 rounded-md border text-gray-700 focus:outline-[#00a6b265] bg-[#EBF8F9]
                      focus:bg-white shadow-md dark:bg-gray-400 dark:text-gray-200 dark:placeholder:text-gray-100"
                />
  
                {errors.duration && (
                  <p className="text-red-600">{errors.duration.message}</p>
                )}
              </div>
              <hr className="my-3" />
  
              <div className="space-y-0.5   text-sm">
                <input

                defaultValue={singleClass?.description?.[1]}
                  type="text"
                  {...register("weekdays", {
                    required: "Weekdays is required",
                  })}
                  id="weekdays"
                  placeholder="Weekdays"
                  className="w-full px-4 py-3 rounded-md border text-gray-700 focus:outline-[#00a6b265] bg-[#EBF8F9]
                      focus:bg-white shadow-md dark:bg-gray-400 dark:text-gray-200 dark:placeholder:text-gray-100"
                />
  
                {errors.weekdays && (
                  <p className="text-red-600">{errors.weekdays.message}</p>
                )}
              </div>
              <hr className="my-3" />
  
              <div className="space-y-0.5   text-sm">
                <input

                defaultValue={singleClass?.openingHour}
                  type="text"
                  {...register("openingHour", {
                    required: "Opening Hours is required",
                  })}
                  id="openingHour"
                  placeholder="Opening Hours (Start) - Opening Hours (End)"
                  className="w-full px-4 py-3 rounded-md border text-gray-700 focus:outline-[#00a6b265] bg-[#EBF8F9]
                      focus:bg-white shadow-md dark:bg-gray-400 dark:text-gray-200 dark:placeholder:text-gray-100"
                />
  
                {errors.openingHour && (
                  <p className="text-red-600">{errors.openingHour.message}</p>
                )}
              </div>
  
              <hr className="my-3" />
  
              <div className="space-y-0.5   text-sm flex items-center justify-evenly">
                <div>
                  <input
                  defaultValue={singleClass?.registration}
                    type="number"
                    {...register("registration", {
                      required: "Required",
                    })}
                    id="registration"
                    placeholder="Registration Fee: $"
                    className="w-full px-4 py-3 rounded-md border text-gray-700 focus:outline-[#00a6b265] bg-[#EBF8F9]
                      focus:bg-white shadow-md dark:bg-gray-400 dark:text-gray-200 dark:placeholder:text-gray-100"
                  />
  
                  {errors.registration && (
                    <p className="text-red-600 mt-1">
                      {errors.registration.message}
                    </p>
                  )}
                </div>
                <div>
                  <input
                  defaultValue={singleClass?.tuition}
                    type="number"
                    {...register("tuition", {
                      required: "Required",
                    })}
                    id="tuition"
                    placeholder="Tuition Fee: $"
                    className="w-full px-4 py-3 rounded-md border text-gray-700 focus:outline-[#00a6b265] bg-[#EBF8F9]
                      focus:bg-white shadow-md dark:bg-gray-400 dark:text-gray-200 dark:placeholder:text-gray-100"
                  />
  
                  {errors.tuition && (
                    <p className="text-red-600 mt-1">{errors.tuition.message}</p>
                  )}
                </div>
                <div>
                  <input

                  defaultValue={singleClass?.books}
                    type="number"
                    {...register("books", {
                      required: "Required",
                    })}
                    id="books"
                    placeholder="Textbook Fee: $"
                    className="w-full px-4 py-3 rounded-md border text-gray-700 focus:outline-[#00a6b265] bg-[#EBF8F9]
                      focus:bg-white shadow-md dark:bg-gray-400 dark:text-gray-200 dark:placeholder:text-gray-100"
                  />
  
                  {errors.books && (
                    <p className="text-red-600">{errors.books.message}</p>
                  )}
                </div>
              </div>
  
              <button className="block w-full p-3 text-center rounded-sm  bg-primary hover:bg-secondary text-white mt-6">
                Upload
              </button>
            </form>
          </div>
        </section>
      </div>
    );
};

export default ClassEditForm;